import {HomePage} from "./Home/HomePage";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {EmailVerifyPage} from "./EmailVerify/EmailVerifyPage";
import {ChangePasswordPage} from "./ChangePassword/ChangePasswordPage";
import {Footer} from "./Footer/Footer";
import ReactGA from "react-ga4";

function preparePathForAnalytics() {
    if (window.location.pathname.includes('bound_email')) {
        return '/bound_email/'
    }
    if (window.location.pathname.includes('change_password')) {
        return '/change_password/'
    }
    return window.location.pathname
}

function initializeAnalytics() {
    if (process.env.REACT_APP_GA_ID) {
        ReactGA.initialize(process.env.REACT_APP_GA_ID, {
            gaOptions: {
                page: preparePathForAnalytics()
            }
        });
    }
}

initializeAnalytics()

export const App = () => {
    return (
        <div className="root">
            <BrowserRouter basename={process.env.PUBLIC_URL}>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/bound_email/:verifyCode' element={<EmailVerifyPage />} />
                    <Route path='/change_password/:userKey/:expirationKey' element={<ChangePasswordPage />} />
                    <Route path='/promotion' element={<HomePage autoNavigate />} />
                    <Route path='/*' element={<Navigate to='/' />} />
                </Routes>
            </BrowserRouter>
            <Footer />
        </div>
    )
}