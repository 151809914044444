import logo from '../assets/images/logo.svg'

import '../styles/variables.scss'
import '../styles/zeroing.scss'
import '../styles/style.scss'
import {useEffect, useState} from "react";
import ReactGA from "react-ga4";

const URL_GOOGLE_PLAY = "http://play.google.com/store/apps/details?id=com.tikboost.fantik"
const URL_APP_GALLERY = null;
const URL_APP_STORE = "https://apps.apple.com/gb/app/fametok-viral-fans-growth/id6695746961"

function generateReferrerUrl(url, source, medium, compaing) {
    let builder = new URL(url)
    let referrer = new URL('http://play.google.com')
    referrer.searchParams.set('utm_source', source)
    referrer.searchParams.set('utm_medium', medium)
    referrer.searchParams.set('utm_campaign', compaing)
    let query = referrer.search.substring(1)
    builder.searchParams.set('referrer', query)
    return builder.toString()
}

function detectBrowser() {
    const standalone = window.navigator.standalone,
        userAgent = window.navigator.userAgent.toLowerCase(),
        safari = /safari/.test(userAgent),
        ios = /iphone|ipod|ipad/.test(userAgent),
        android = /(android)/i.test(userAgent),
        hmscore = /(hmscore)/i.test(userAgent),
        harmonyos = /(harmonyos)/i.test(userAgent);

    if (ios) {
        if ( !standalone && safari ) {
            console.log('iOS Browser')
            return { type: 'ios', subtype: 'Browser' }
        } else if ( standalone && !safari ) {
            console.log('iOS Standalone')
            return { type: 'ios', subtype: 'Standalone' }
        } else if ( !standalone && !safari ) {
            console.log('iOS WebView')
            return { type: 'ios', subtype: 'WebView' }
        } else {
            console.log('iOS Unknown')
            return { type: 'ios', subtype: 'Unknown' }
        }
    } else if (android) {
        if (harmonyos || hmscore) {
            return { type: 'huawei', subtype: '' }
        }
        return { type: 'android', subtype: window.navigator.userAgent }
    } else {
        if (harmonyos || hmscore) {
            return { type: 'huawei', subtype: '' }
        }
        return { type: 'unknown', subtype: window.navigator.userAgent }
    }
}

function navigationEvent(action) {
    ReactGA.event({
        category: "navigation",
        action: action,
    });
}

function navigateTo(url, store) {
    navigationEvent('button_navigate_' + store)
    window.open(url, '_self')
}

export const HomePage = ({ autoNavigate = false }) => {
    const [browser, setBrowser] = useState(null)
    useEffect(() => {
        setBrowser(detectBrowser())
    }, []);
    useEffect(() => {
        if (autoNavigate) {
            if (browser !== null) {
                if (browser.type === 'huawei' && URL_APP_GALLERY) {
                    navigationEvent('auto_navigate_appgallery')
                    window.open(URL_APP_GALLERY, '_self')
                } else if ((browser.type === 'android' || browser.type === 'huawei') && URL_GOOGLE_PLAY) {
                    navigationEvent('auto_navigate_googleplay')
                    window.location.href = generateReferrerUrl(URL_GOOGLE_PLAY, 'fantik_app', 'promotion', 'auto_promotion')
                } else if (browser.type === 'ios' && URL_APP_STORE) {
                    navigationEvent('auto_navigate_appstore')
                    window.open(URL_APP_STORE, '_self')
                }
            }
        }
    }, [browser, autoNavigate]);
    return (
        <main className="main">
            <section className="container">
                <header className="header">
                    <img
                        src={logo}
                        alt="Logo"
                        className="logo__img"
                    />
                    <p>FanTik</p>
                </header>
                <section className="wrapper">
                    <div className="about__activity">
                        <h1 className="about__activity_title">
                            Become more popular with us
                        </h1>
                        <p className="about__activity_text">
                            Boost your profile! Get more likes, views, followers
                            and comments
                        </p>

                        <div className="buttons__links">
                            {URL_APP_STORE !== null && <div onClick={() => navigateTo(URL_APP_STORE, 'appstore')} className="btn btn_appStore">
                                <p>App Store</p>
                            </div>}
                            {URL_GOOGLE_PLAY !== null && <div onClick={() => navigateTo(generateReferrerUrl(URL_GOOGLE_PLAY, 'fantik_app', autoNavigate ? 'promotion' : 'home', autoNavigate ? 'button_promotion' : 'button_home'), 'googleplay')} rel="noreferrer nofollow" className="btn btn_googlePlay">
                                <p>Google Play</p>
                            </div>}
                        </div>
                    </div>
                </section>
            </section>
        </main>
    )
}
